<template>
  <div class="epidemicNews">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="main">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="list-content"
            v-for="(item, index) in listData"
            :key="index"
            @click="toRouteTitle(item)"
          >
            <div class="list-sidebar">
              <img :src="item.topicPicture" />
            </div>
            <div class="list-wrapper">
              <div class="left-title">
                <div class="img" v-if="item.isTop == 1">
                  <img src="./img/hot.png" />
                </div>
                <div class="name">{{ item.topicTitle }}</div>
              </div>
              <div class="left-content">
                {{ item.remark || "" }}
              </div>
              <div class="date">
                {{ item.createTime || "" }}
              </div>
              <div class="left-wrap">
                <div class="iconText">
                  <div class="icon">
                    <img src="./img/icon-hot.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.readCount }}</span>
                  </div>
                </div>
                <div class="iconText">
                  <div class="icon icon-up">
                    <img src="./img/icon-msg.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.commentCount }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { getTopicListURL } from "./api.js";

import { gloabalCount } from "@/utils/common.js";

export default {
  name: "epidemicNews",
  components: {},
  data() {
    return {
      navKey: "NEW",
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 20,
      curIndex: undefined, // 当前点击的索引
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {},
  mounted() {
    // gloabalCount("", 21, 1);
  },
  methods: {
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            houseId: this.houseId || this.communityId || undefined,
            tenantId: this.tenantId,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 16, // 1. 和伙人风采 2.超话 3. 活动 4.雅曼广播站
            curPage: this.curPage,
            pageSize: this.pageSize,
            sortField: this.navKey, // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.listData.forEach((ele) => {
              if (ele.topicPicture) {
                ele.topicPicture = this.$handleImg(192, 192, ele.topicPicture);
              }
            });
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      // this.listData = [];
      this.onLoad();
    },

    // 点击跳转话题详情页
    toRouteTitle(item) {
      // this.$router.push({
      //   name: "topicTitle",
      //   query: {
      //     id: item.id,
      //   },
      // });
      if (item.editType == 0) {
        let params = {
          id: item.id,
        };
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/epidemicNewsDetail/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      } else if (item.editType == 1) {
        wx.miniProgram.navigateTo({
          url: `/pages/webview/index?url=${item.externalUrl}`,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.epidemicNews {
  box-sizing: border-box;
  background: #fafafa;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
  ::v-deep .van-pull-refresh {
    min-height: 100vh;
  }
  .main {
    box-sizing: border-box;
    margin: 22px 0 0 0;
    .list-content {
      border-radius: 16px;
      background: #ffffff;
      display: flex;
      overflow: hidden;
      margin: 0 32px 32px 32px;
      height: 256px;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      .list-sidebar {
        width: 256px;
        min-width: 256px;
        height: 100%;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .list-wrapper {
        flex: 1;
        max-width: calc(100% - 256px);
        padding: 36px 30px 24px 28px;
        box-sizing: border-box;

        .left-title {
          font-size: 32px;
          font-weight: bold;
          color: #333333;
          line-height: 44px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 12px;
        }

        .left-content {
          margin-bottom: 66px;

          > span {
            padding: 4px 10px;
            box-sizing: border-box;
            background: #fff3eb;
            border-radius: 4px;
            border: 2px solid #ffc7a4;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fe6f16;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .date {
          font-size: 26px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 36px;
        }
        .left-wrap {
          display: flex;
          align-items: center;
          .iconTag {
            flex: 1;
            overflow: hidden;
            margin-right: 8px;
            display: flex;
            align-items: center;

            > span {
              height: 32px;
              display: flex;
              align-items: center;
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              border-radius: 4px;
              font-size: 24px;
              background: #fff3eb;
              border-radius: 8px;
              border: 2px solid #ffc7a4;
              padding: 0px 8px;
              color: #fe6f16;
            }
          }

          .iconText {
            display: flex;
            color: #a8a8a8;
            align-items: center;

            &:not(:last-child) {
              margin-right: 32px;
            }

            .icon {
              display: flex;
              align-items: center;

              &.icon-up {
                > img {
                  margin-top: 4px;
                }
              }

              > img {
                width: 36px;
                height: 36px;
              }
            }

            .text {
              margin-left: 8px;
              display: flex;
              align-items: center;
              height: 36px;
              line-height: 36px;

              span {
                &.hasPraised {
                  color: #ff853a;
                }

                font-size: 24px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
